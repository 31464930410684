
import { downLoad, getCustTypeSelect, getFxCust, getPromotionMethods, getproducts, queryOrg, saveMarketActivity } from './api'
import moment from 'moment';
import Util from "@/utils/utils";
import axios from "axios";
import { publicPath } from "@/common/constant.js";
export default {
    name: "",
    data() {
        return {
            breadcrumbData: [
                {
                    path: "/index",
                    name: "index",
                    title: "首页"
                },
                {
                    path: "/activityConfigurationList",
                    name: "营销活动",
                    title: "营销活动管理"
                },
                {
                    path: "/activityConfigurationList/add",
                    name: "营销活动新增",
                    title: "营销活动新增"
                }
            ],
            columns: [
                {
                    label: "客户名称",
                    prop: "name",
                },
                {
                    label: "渠道范围",
                    prop: "desc",
                },
                // {
                //     label: "是否可参与",
                //     slot: "yesOrNo",
                //     width: 150,
                // },
            ],
            tableData: [],// 弹出框指定分销商的数据
            shopArr: [],
            isActivePLevel: 0,// 专卖店点击赋值
            positinLevel: '',
            shopTypeId: '',//选中的
            appoint: false,
            showLoading: false,
            MarketTableList: [
                {
                    matklId: 0,
                    matklName: '否'
                },
                {
                    matklId: 1,
                    matklName: '是'
                }
            ],

            requestObj: {
                activityName: '',// 活动名称
                endTime: '', // 结束时间
                timeStart: '', // 开始时间
                stopFlag: '',// 是否暂停
                saleType: '',// 促销方式
                orgId: ''
            },
            stopList: [
                {
                    code: 'Y',
                    name: '是'
                },
                {
                    code: 'N',
                    name: '否'
                }
            ],
            saleArr: [],
            orgArr: [],
            selectedRowKeys: [],
            selectedRowKeysfx: [],
            // 直降、后返 - 产品型号 定价组 提货价 数量 开票单价 单个商家限量  本次活动返利  活动说明 备注
            // 套购 组合编码 产品型号 定价组 提货价 数量 开票单价 组合数量 单个商家限量 返利比例 本次活动返利  活动说明 备注
            // 组合购 组合编码 产品分组编码 产品分组描述 产品型号 定价组 提货价 数量 开票单价 组合数量 单个商家限量 返利比例 本次活动返利  是否默认 活动说明 备注 
            directColumn: [
                {
                    title: "产品型号*",
                    dataIndex: "productInfoZzprdmodel",
                    width: 250,
                    scopedSlots: { customRender: 'productInfoZzprdmodel' },
                },

                {
                    title: "提货价",
                    dataIndex: "standardPrice",
                    width: 150,
                    scopedSlots: { customRender: 'standardPrice' },
                },
                {
                    title: "数量*",
                    dataIndex: "qty",
                    width: 150,
                    scopedSlots: { customRender: 'qty' },
                },
                {
                    title: "开票单价*",
                    dataIndex: "billPrice",
                    width: 150,
                    scopedSlots: { customRender: 'billPrice' },
                },
                {
                    title: "单个商家限量",
                    dataIndex: "purchaseLimitQty",
                    width: 150,
                    scopedSlots: { customRender: 'purchaseLimitQty' },
                },

                {

                    title: "本次活动返利",
                    dataIndex: "rebateMoney",
                    width: 150,
                    scopedSlots: { customRender: 'rebateMoney' },
                },

                {
                    title: "活动说明",
                    dataIndex: "productSubtitle",
                    width: 250,
                    scopedSlots: { customRender: 'productSubtitle' },
                },
                {
                    title: "备注",
                    dataIndex: "remark",
                    width: 250,
                    scopedSlots: { customRender: 'remark' },
                },

            ],
            arbitrageColumn: [
                {
                    title: "组合编码*",
                    dataIndex: "packageCode",
                    width: 250,
                    scopedSlots: { customRender: 'packageCode' },
                },
                {
                    title: "产品型号*",
                    dataIndex: "productInfoZzprdmodel",
                    width: 250,
                    scopedSlots: { customRender: 'productInfoZzprdmodel' },
                },

                {
                    title: "提货价",
                    dataIndex: "standardPrice",
                    width: 150,
                    scopedSlots: { customRender: 'standardPrice' },
                },
                {
                    title: "数量*",
                    dataIndex: "qty",
                    width: 150,
                    scopedSlots: { customRender: 'qty' },
                },
                {
                    title: "开票单价*",
                    dataIndex: "billPrice",
                    width: 150,
                    scopedSlots: { customRender: 'billPrice' },
                },
                {
                    title: "组合数量*",
                    dataIndex: "packageNum",
                    width: 150,
                    scopedSlots: { customRender: 'packageNum' }
                },
                {
                    title: "单个商家限量",
                    dataIndex: "purchaseLimitQty",
                    width: 150,
                    scopedSlots: { customRender: 'purchaseLimitQty' },
                },

                {

                    title: "本次活动返利",
                    dataIndex: "rebateMoney",
                    width: 250,
                    scopedSlots: { customRender: 'rebateMoney' },
                },

                {
                    title: "活动说明",
                    dataIndex: "productSubtitle",
                    width: 250,
                    scopedSlots: { customRender: 'productSubtitle' },
                },
                {
                    title: "备注",
                    dataIndex: "remark",
                    width: 250,
                    scopedSlots: { customRender: 'remark' },
                },

            ],
            combinationColumn: [

                {
                    title: "组合编码*",
                    dataIndex: "packageCode",
                    width: 250,
                    scopedSlots: { customRender: 'packageCode' },
                },
                {
                    title: "产品分组编码*",
                    dataIndex: "productGroup",
                    width: 250,
                    scopedSlots: { customRender: 'productGroup' },
                },
                {
                    title: "产品分组描述*",
                    dataIndex: "productGroupRemark",
                    width: 250,
                    scopedSlots: { customRender: 'productGroupRemark' },
                },
                {
                    title: "产品型号*",
                    dataIndex: "productInfoZzprdmodel",
                    width: 250,
                    scopedSlots: { customRender: 'productInfoZzprdmodel' },
                },

                {
                    title: "提货价",
                    dataIndex: "standardPrice",
                    width: 150,
                    scopedSlots: { customRender: 'standardPrice' },
                },
                {
                    title: "数量*",
                    dataIndex: "qty",
                    width: 150,
                    scopedSlots: { customRender: 'qty' },
                },
                {
                    title: "开票单价*",
                    dataIndex: "billPrice",
                    width: 150,
                    scopedSlots: { customRender: 'billPrice' },
                },
                {
                    title: "组合数量*",
                    dataIndex: "packageNum",
                    width: 150,
                    scopedSlots: { customRender: 'packageNum' }
                },
                {
                    title: "单个商家限量",
                    dataIndex: "purchaseLimitQty",
                    width: 150,
                    scopedSlots: { customRender: 'purchaseLimitQty' },
                },
                {

                    title: "本次活动返利",
                    dataIndex: "rebateMoney",
                    width: 150,
                    scopedSlots: { customRender: 'rebateMoney' },
                },

                {
                    title: "是否默认*",
                    dataIndex: "defaultFlag",
                    width: 250,
                    scopedSlots: { customRender: 'defaultFlag' },
                },
                {
                    title: "活动说明",
                    dataIndex: "productSubtitle",
                    width: 250,
                    scopedSlots: { customRender: 'productSubtitle' },
                },
                {
                    title: "备注",
                    dataIndex: "remark",
                    width: 250,
                    scopedSlots: { customRender: 'remark' },
                },

            ],
            InitializeColumn: [],
            tableDatashopAer: [],
            FXDLlnum: '', // 模糊搜索 产品
            FXDLlist: [], // 模糊搜索出来的数据
            dlId: '',
            fetching: false,
            selRows: [],
            pageLoading: false,
            fileList: [],
            showproduLoading:false

        };
    },

    mounted() {
        this.getShopType()
        this.getSaleType()
        this.getOrg()
        // 初始化默认组合购表头
        this.InitializeColumn = this.combinationColumn
        this.requestObj.stopFlag = 'N'
    },
    methods: {
        // 下载模板
        downLoadFile() {
            let data = {

            }
            downLoad(data).then(res => {
                this.isLoading = false;
                Util.blobToJson(res.data).then(content => {
                    if (content && content.msg == 'success') {
                        this.$message.success('下载成功，请到下载中心查看!')
                    }
                }).catch(err => {
                    Util.downloadFile(res.data, '导入模板.xls')
                }).finally(() => {
                    this.isLoading = false;
                })
            })
        },
        // 导入商品信息
        beforeUpload(file) {
            this.fileList = [...this.fileList, file];
            this.fileList = this.fileList.slice(-1);
            return false;
        },
        handleChange({ fileList }) {
            this.fileList = fileList;
            this.fileList = this.fileList.slice(-1);
            if (this.fileList.length > 0) {
                this.importExcel(fileList)
            }
        },
        // 导入数据 
        importExcel(fileList) {
            this.showproduLoading = true
            let formData = new FormData()
            formData.append('file', fileList[0].originFileObj);
            let config = {
                headers: { 'content-type': 'multipart/form-data', },
            }
            // 发送请求
            const BASE_API = publicPath;
            axios.post(BASE_API + '/b2bMarketActivity/importProduct.nd', formData, config).then(res => {
                res.data.forEach(item => {
                    item.defaultFlag = Number(item.defaultFlag)
                })
                this.tableDatashopAer.push(...res.data)
            })
            this.showproduLoading = false
        },
        // 返回
        goback() {
            window.close()
            this.$router.push({ path: '/activityConfigurationList' });
        },
        // 提交数据
        sumbit(type) {
            if (!this.requestObj.activityName) {
                this.$message.warning('请输入活动名称')
                return
            }
            if (!this.requestObj.orgId) {
                this.$message.warning('请选择销售组织')
                return
            }
            if (!this.requestObj.saleType) {
                this.$message.warning('请选择促销方式')
                return
            }
            if (!this.requestObj.stopFlag) {
                this.$message.warning('请选择是否暂停')
                return
            }
            if (!this.requestObj.timeStart) {
                this.$message.warning('请选择开始时间')
                return
            }
            if (!this.requestObj.endTime) {
                this.$message.warning('请选择结束时间')
                return
            }
            if (this.tableDatashopAer.length <= 0) {
                this.$message.warning('请填写产品关系数据')
                return
            }

            if (this.requestObj.saleType == 90605) {
                let groupList = []
                this.tableDatashopAer.forEach(item => {
                    if (groupList.every(value => value.productGroup !== item.productGroup || value.packageCode !== item.packageCode)) {
                        groupList.push({
                            productGroup: item.productGroup, // 产品分组编码
                            packageCode: item.packageCode, // 组合编码
                            defaultFlagIsYes: Number(item.defaultFlag),
                            defaultFlagISYesCount: Number(item.defaultFlag) === 1 ? 1 : 0,
                            purchaseLimitQty: Number(item.purchaseLimitQty), // 单个商家限量数量
                            purchaseLimitQtySum: Number(item.purchaseLimitQty),
                            packageNum: Number(item.packageNum), // 组合数量
                            packageNumIsDifferent: 0,
                            productGroupRemark: item.productGroupRemark,
                            productGroupRemarkDifferent: 0,
                            productInfoZzprdmodel: item.productInfoZzprdmodel,
                            productInfoZzprdmodelDifferent: 1
                        })
                    } else {
                        groupList.forEach(groupData => {
                            if (groupData.productGroup === item.productGroup && groupData.packageCode === item.packageCode) {
                                if (Number(item.defaultFlag) === 1) {
                                    groupData.defaultFlagIsYes = 1
                                    groupData.defaultFlagISYesCount += 1
                                }
                                if (groupData.packageNum !== Number(item.packageNum)) {
                                    groupData.packageNumIsDifferent += 1
                                }
                                if (groupData.productGroupRemark !== item.productGroupRemark) {
                                    groupData.productGroupRemarkDifferent += 1
                                }
                                if (groupData.productInfoZzprdmodel == item.productInfoZzprdmodel) {
                                    groupData.productInfoZzprdmodelDifferent += 1
                                }

                                groupData.purchaseLimitQtySum += Number(item.purchaseLimitQty)
                            }
                        })
                    }
                })

                for (let index = 0; index < groupList.length; index++) {
                    const groupData = groupList[index]
                    if (groupData.packageNumIsDifferent > 0) {
                        this.$message.warning(groupData.packageCode + '组合编码下' + '产品分组编码为' + groupData.productGroup + '的组合数量必须一致，请重新填写')
                        // this.$message.warning('相同产品分组编码的组合数量必须一致，请重新填写')
                        return
                    }
                    if (groupData.defaultFlagIsYes !== 1) {
                        this.$message.warning(groupData.packageCode + '组合编码下' + '产品分组编码为' + groupData.productGroup + '的' + '是否默认选项必须有一个为是，请重新选择')
                        return
                    }
                    if (groupData.defaultFlagISYesCount > 1) {
                        this.$message.warning(groupData.packageCode + '组合编码下' + '产品分组编码为' + groupData.productGroup + '的' + '是否默认选项只能有一个为是，请重新选择')
                        return
                    }
                    if (groupData.productGroupRemarkDifferent > 0) {
                        this.$message.warning(groupData.packageCode + '组合编码下' + '产品分组编码为' + groupData.productGroup + '的' + '产品分组描述必须一致，请重新填写')
                        return
                    }
                    if (groupData.purchaseLimitQtySum < groupData.packageNum) {
                        this.$message.warning(groupData.packageCode + '组合编码下' + '产品分组编码为' + groupData.productGroup + '的' + '产品单个产品限量之和不能小于组合数量，请重新填写')
                        return
                    }
                    if (groupData.productInfoZzprdmodelDifferent > 1) {
                        this.$message.warning(groupData.packageCode + '组合编码下' + '产品分组编码为' + groupData.productGroup + '的' + '产品型号不能选择相同的，请重新选择')
                        return
                    }
                }


            }
            if(this.requestObj.saleType == 90605 || this.requestObj.saleType == 90603) {
                debugger
                for(let i = 0;i<this.tableDatashopAer.length;i++) {
                    if(this.tableDatashopAer[i].packageNum <= 0 ) {
                        this.$message.warning('组合数量不能为0，请修改')
                        return
                    }
                }
            }
            let FXId = []
            this.selectedRowKeysfx.forEach(item => {
                FXId.push({
                    id: item
                })
            })
            let data = {
                activityName: this.requestObj.activityName,
                startDate: this.requestObj.timeStart,
                endDate: this.requestObj.endTime,
                discountTypeId: this.requestObj.saleType,
                pause: this.requestObj.stopFlag,
                fwOrgId: this.requestObj.orgId,
                custDtoList: FXId,
                productDtoList: this.tableDatashopAer,
                statusDesc: type,
                custTag: [this.shopTypeId]
            }

            this.pageLoading = true
            saveMarketActivity(data).then(res => {
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg)
                    setTimeout(function () {
                        // 关闭窗口之前刷新父页面
                        window.opener.location.reload();
                        window.close()
                        this.$router.push({ path: '/activityConfigurationList' });

                    }, 1500);
                } else {
                    this.$message.warning(res.data.msg)
                }
                this.pageLoading = false
            })
        },
        changeDl(record, index, value, e) {
            // this.dlId = value
            this.tableDatashopAer.forEach((item, tableindex) => {
                if (tableindex == index) {
                    record.productInfoZzprdmodel = value
                }
            })
        },

        // 输入模糊搜索代理商
        getDLList(value) {
            this.fetching = true
            this.FXDLlnum = value
            let data = {
                key: this.FXDLlnum
            }
            getproducts(data).then(res => {
                this.FXDLlist = [...new Set(res.data)]; //缓存列表数据，前端分页
                this.fetching = false
            })
        },
        //查询数据
        search() {

        },

        //删除行数据
        delLine() {
            if (this.selectedRowKeys.length <= 0) {
                this.$message.warning('请至少选择一项数据')
            } else {
                this.tableDatashopAer = this.tableDatashopAer.filter((item, index) => !this.selectedRowKeys.includes(index))
                this.selectedRowKeys = []
            }

        },
        // // 勾选数据
        // onSelectChange(selectedRowKeys) {
        //     this.selectedRowKeys = selectedRowKeys;
        // },
        onChange(selectedRowKeys, selRows) {
            this.selRows = selRows;
            this.selectedRowKeys = selectedRowKeys

        },
        onChangefx(selRows) {
            this.selRowsFX = selRows;
            this.selectedRowKeysfx = selRows.map(it => it.code);
        },
        // 添加行
        addline() {
            this.tableDatashopAer.push(
                {

                }
            )
        },
        // 开始时间更改
        handleTimeChange(e, defaultTime) {
            this.requestObj.timeStart = defaultTime;
        },
        // 结束时间更改
        handleendtime(e, defaultTime) {
            this.requestObj.endTime = defaultTime;
        },
        // 修改促销方式
        handleChangeSale(e) {
            this.tableDatashopAer = []
            this.InitializeColumn = []

            if (this.requestObj.saleType == 90601 || this.requestObj.saleType == 90602) {
                this.InitializeColumn = this.directColumn
            }
            if (this.requestObj.saleType == 90603) {
                this.InitializeColumn = this.arbitrageColumn
            }
            if (this.requestObj.saleType == 90605) {
                this.InitializeColumn = this.combinationColumn
            }

        },
        // 获取促销方式
        getSaleType() {
            getPromotionMethods({}).then(res => {
                this.saleArr = res.data
            })
        },
        getOrg() {
            let data = {
                type: 2
            }
            queryOrg(data).then(res => {
                this.orgArr = res.data.orgList
            })
        },

        // 更改是否暂停
        onGiveType(e) {
            this.requestObj.stopFlag = e.target.value
        },
        // 开始时间选择不能选择今天之前的日期
        disabledDate(current) {
            return current && current < moment().startOf('day');
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },

        // 更改营销
        handleChangeshopList(item, index, event) {
            item.row.matklName = event
            this.MarketTableList.forEach(marItem => {
                if (event == marItem.matklName) {
                    this.$set(item.row.matklId = marItem.matklId)
                    item.row.matklId = marItem.matklId
                }
            })
            // this.$forceUpdate();

        },
        // 更改是否默认
        changeNoOrYes(record, index, e,) {
            this.tableDatashopAer.forEach((item, tableindex) => {
                if (tableindex == index) {
                    record.defaultFlag = e
                }
            })
        },
        getFxList() {
            let data = {}
            this.showLoading = true
            getFxCust(data).then(res => {
                this.tableData = res.data
                this.showLoading = false

            })
        },

        // 商家类型选择
        btnClickLevel(index, id, item) {
            this.isActivePLevel = index
            this.shopTypeId = id
            item.isCheck = true
            if (this.shopTypeId == '2') {
                this.getFxList()
            }
        },
        // 获取商家类型
        getShopType() {
            let data = {
            }
            getCustTypeSelect(data).then(res => {
                this.shopArr = res.data
                this.shopTypeId = this.shopArr[0].code
                this.shopArr.forEach(item => {
                    item.isCheck = false
                })
            })
        }
    }

};
