
import http from "@/utils/request";

// 获取列表
export function getCustTypeSelect(data) {
    return http({
        url: '/b2bMarketActivity/getCustTypeSelect.nd',
        method: 'post',
        data
    })
}
export function getFxCust(data) {
    return http({
        url: '/b2bMarketActivity/getFxCust.nd',
        method: 'post',
        data
    })
}
export function getPromotionMethods(data) {
    return http({
        url: '/b2bMarketActivity/getPromotionMethods.nd',
        method: 'post',
        data
    })
}
export function getproducts(data) {
    return http({
        url: '/rest/productKeywords.nd',
        method: 'post',
        data
    })
}

// /comm/queryOrg.nd
export function queryOrg(data) {
    return http({
        url: '/comm/queryOrg.nd',
        method: 'post',
        data
    })
}

export function saveMarketActivity(data) {
    return http({
        url: '/b2bMarketActivity/addB2bMarketActivity.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    })
}
// 导出模板下载

export function downLoad(data) {
    return http({
        url: '/b2bMarketActivity/getExcelForProductImport.nd',
        method: 'post',
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        responseType: 'blob'
       
    })
}